import { MatMenuModule } from '@angular/material/menu';
import { BreakpointState } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ViewChild, inject, signal } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Constants } from 'app/core/data/constants';
import { Observable, Subject, catchError, filter, map, of, takeUntil, tap } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NavigationItemModel } from 'app/core/models/navigation-item.model';
import { navigationItems } from 'app/core/data/navigation-data';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { CookiesUtilsService } from 'app/services/cookies-utils/cookies-utils.service';
import { HelperService } from 'app/services/helpers/helper.service';
import { selectAuthentication } from 'app/store/reducers';
import { AuthenticationState } from 'app/store/states/authentication.state';
import { ButtonsMaterialModule } from 'app/shared/angular-material/buttons-material.module';
import { MatDividerModule } from '@angular/material/divider';
import { fuseAnimations } from 'app/core/fuse/animations';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { PricesPopupComponent } from '../prices-popup/prices-popup.component';
import { PricesPopupConfig } from '../prices-popup/prices-popup-config';
import { CandidateModel } from 'app/core/models/candidate.model';
import { DataLogicService } from 'app/services/data-logic/data-logic.service';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    ButtonsMaterialModule, CommonModule, SharedModule,
    MatToolbarModule, SharedModule, MatMenuModule, MatDividerModule,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  animations: fuseAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('header', { read: ElementRef }) headerRef?: ElementRef;
  @Input() customHeaderClasses?: string[];
  candidateId: number;
  getCandidateRequested: boolean = false;
  _store = inject(Store)
  _cookiesUtilsService = inject(CookiesUtilsService)
  _screenChangesService = inject(HelperService)
  _platformId = inject(PLATFORM_ID)
  navOpened = signal(false);
  private _router: Router = inject(Router)
  isLogged = signal(false);
  user: any = {};
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private _renderer = inject(Renderer2)
  slideTop = signal(false)
  navItems: NavigationItemModel[] = [];
  currentBreakPoint = toSignal(this.getBreakpointChanges());
  recruiterUrl = environment.ENDPOINTS.SPARTAN_URL;
  hidefindJobNavItems = signal(false);
  public dialog = inject(MatDialog);
  _candidateService = inject(DataLogicService<CandidateModel>)
  private _authState = toSignal(this._store.select(selectAuthentication).pipe(map((authState: AuthenticationState) => authState)));

  ngOnInit(): void {
    /** check if current route start with /search */
    this.hidefindJobNavItems.set(this._router.url.startsWith("/search"));

    /** auth state listener */
    this.authStateListener();
  }

  ngAfterViewInit(): void {
    this.changeHeaderStatus()
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /** scroll handeler */
  @HostListener('window:scroll', [])
  onScroll() {
    this.changeHeaderStatus()
  }


  /** auth state listener */
  authStateListener(): void {
    this._store.select(selectAuthentication).pipe(
      takeUntil(this._unsubscribeAll),
      tap((authState: AuthenticationState) => {
        /// set is logged and user info
        this._setLoggedAndUserData({ authState });
        /// get header elements
        this._getHeaderElemens();
      })
    ).subscribe()
  }


  /** set login infos */
  private _setLoggedAndUserData({ authState }: { authState: AuthenticationState }): void {
    this.isLogged.set(authState.isLoggedIn);
    if (this.isLogged()) {
      const token = this._cookiesUtilsService.getDecodedAccessToken();
      this.user.email = token?.sub;
    }
  }


  /** get header elements */
  private _getHeaderElemens(): void {
    const allHeaderElements: NavigationItemModel[] = navigationItems;
    this.navItems = this.isLogged() ?
      allHeaderElements.filter((headerElement: NavigationItemModel) => headerElement.privilege !== 'public-only')
      :
      allHeaderElements.filter((headerElement: NavigationItemModel) => headerElement.privilege === 'public' || headerElement.privilege === 'public-only')
        .map((headerElement: NavigationItemModel) => headerElement);

    if (this.hidefindJobNavItems()) {
      this.navItems = this.navItems.filter((headerElement: NavigationItemModel) => headerElement.title !== 'find_job');
    }
  }



  handleNav(): void {
    this.navOpened.update((navOpened) => !navOpened)
  }

  signIn(): void {
    this._router.navigate([Constants.LOGIN_ROUTE]);
  }

  loginUrl = `/${Constants.LOGIN_ROUTE}`;

  onHeaderItemClicked({ navItem }: { navItem: NavigationItemModel }, event?: Event): void {
    if (navItem?.id === 'prices') {
      event.preventDefault();
      this.showPricesPopUp();
    }
  }
  /* navigate to profile */
  navProfile(): void {
    if (this._authState()?.isFirstLogin) {
      this._router.navigate([Constants.ACCOUNT_FINALIZATION_ROUTE]);
    } else {
      this._router.navigate([Constants.PROFILE_ROUTE]);
    }
  }


  /* sign out */
  signOut(): void {
    this._router.navigate([Constants.LOGOUT_ROUTE]);
  }

  /** get breakpoint changes */
  getBreakpointChanges(): Observable<BreakpointState | null> {
    if (isPlatformBrowser(this._platformId)) {
      return this._screenChangesService.getBreakpointState().pipe(
        map((breakpoint: BreakpointState) => breakpoint),
        takeUntil(this._unsubscribeAll),
      );
    }
    return of(null);
  }

  /** set active to header or delete it */
  changeHeaderStatus(): void {
    if (isPlatformBrowser(this._platformId)) {
      if (this.headerRef?.nativeElement) {
        if (document.documentElement.scrollTop > 50) {
          this._renderer.addClass(this.headerRef.nativeElement, 'active')
          this.slideTop.set(false);
        } else {
          this.slideTop.set(true);
          this._renderer.removeClass(this.headerRef.nativeElement, 'active')
        }
      }
    }
  }
  /** check if item is active */
  activeItem(item: NavigationItemModel): boolean {
    if (item && item.id !== 'prices') {
      return this._router.url.includes(item.link);
    } else
      return false
  }
  showPricesPopUp(): void {
    const dialogRef = this.dialog.open(PricesPopupComponent, {
      data: this.dataDialog()
    });
    dialogRef.afterClosed().subscribe(result => {
      // TODO conming soon
    }
    );
  }
  dataDialog(): PricesPopupConfig {
    return {
      message: `<img src="assets/images/prices/spartan_Job_price.jpeg" alt="" class="max-w-5xl"/>`,
      dismissible: true,
      styles: {
        message: ['mt-0'],
        dismissible: ['pt-1', 'pr-1'],
        content: ['p-0', 'sm:pb-0', 'pb-0', 'sm:p-0', 'sm:pb-0'],
        dismissibleButton: {
          button: ['text-red-600', 'bg-red-100', 'dark:text-red-50', 'dark:bg-red-600'],
          icon: ['text-blue-600', 'bg-blue-100', 'dark:text-blue-50', 'dark:bg-blue-600'],
          color: '#f43f5e !important'
        }
      }
    }
  }

}



